import {Component} from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import React from 'react';
import { Schemat, Entity, Path } from './schemat';
import { defaultNewInstance, Dictionary, NullableDictionary } from './instance-util';
import { connectionsFrom, connectionsFromPath} from './schemat-read-util';
import { getInstancesByEntity, addInstance, getInstancesByEntityIdLocal } from './instance-state';

interface EntityViewProperties {
  schemat: Schemat,
          path: Path,
          instances: Object[],
          onSelect: (instance: Object) => void
}

interface InstanceHolder {
  instances: Object[]; 

}

interface EntityViewState {
          holder : InstanceHolder;
}

//TODO rename this to PathViewComponent
class EntityViewComponent extends Component<EntityViewProperties, EntityViewState> {

constructor (props: EntityViewProperties){
      super(props);
      const component = this;
      var instances : NullableDictionary[] = []
      this.state = { holder:  { instances: instances } };
      //TODO deal with this assumption that the root is non null
      getInstancesByEntity(props.schemat, props.path.root!!).then(
         (newInstances) => {
           component.setState( {holder:  { instances: newInstances }})
         }
      );
      
      }

          localAddInstance() {
  const id2Object = Object() as Dictionary
  const newInstance = defaultNewInstance(this.props.schemat, this.props.path.root!!, id2Object)

  addInstance(this.props.path.root!!, newInstance as NullableDictionary);
  var newInstances = getInstancesByEntityIdLocal(this.props.path.root!!.id as string);

  // post this event
  this.setState (  { holder:  { instances: newInstances as NullableDictionary[] } })
  
}

render() {

const thisComponent = this;
const path = this.props.path;
const schemat = this.props.schemat;
const connections = connectionsFromPath(schemat, path)
//.filter(
//  ( c ) => { return c.permissions && !c.permissions.deny  }
//);
const theInstances = this.state.holder.instances

if (theInstances!=null) {
  console.log("theInstances", theInstances)
}

function onClickAdd() {
 thisComponent.localAddInstance()
}

function onSelectInstance(instance: Object) {
  thisComponent.props.onSelect(instance)
}

return (
  <React.Fragment>

<Table striped bordered hover>
  <thead>
    <tr>
{connections.map(function (connection, index) {

      return (
        <th>{connection.name}</th>
    )})}
    </tr>    
    </thead>
    <tbody>
  {


  theInstances.map( function(instance, index) {
      var localOnSelect = function() {onSelectInstance(instance)}
      return <tr onClick={localOnSelect}> 
          {connections.map(  function(connection, index) {
              return <td>{"" + (instance as Dictionary)[connection.name as string] }</td>    
          }              
          ) }  
           </tr>
  }
  )
}
    <tr>
       <td colSpan = {connections.length}>
            <Button onClick={onClickAdd} >Add</Button>
       </td>
    </tr>
    </tbody>
    </Table>
    </React.Fragment>
  );
}
}


export {EntityViewComponent}