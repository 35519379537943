import React from 'react';
import {  makeStyles, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import { MetaData, _getMetaData, NullableDictionary, createUpdateEvent, _getId, defaultNewInstance, Dictionary, _setId, InstanceChange, ChangeAction, _setAction } from './instance-util';
import { connectionsFrom, permittedConnectionsFrom } from './schemat-read-util';
import { Connection, Cardinality, ConnectionType, Path } from './schemat';
import Button from 'react-bootstrap/InputGroup';
import { Subject } from 'rxjs';
import { StyledTreeItem, MinusSquare, CloseSquare, PlusSquare } from './StyledTree';
import { PropertyEdit, InstanceProps } from './PropertyEdit';
import { pathToFileURL } from 'url';




type ArrayTreeChildrenState = {
  instances: NullableDictionary[];
}

var nextInstanceId : number =1;
const instance2Id = {} as Dictionary;
function getInstanceId(o: object) : string{
    const id = _getId(o);
    if (instance2Id[id as string]) {
       return instance2Id[id as string] as unknown as string;
    }  else {
      const newId = "" + (nextInstanceId);
      nextInstanceId =  nextInstanceId + 1;
      instance2Id[id as string] = newId as String;
      return newId;
    }
}


function instances(instanceProps: InstanceProps) : object[] {
  return instanceProps.instance as object[]
}

class ArrayTreeChildren extends React.Component<InstanceProps, ArrayTreeChildrenState> {

  childEvent(_i: number, childEvent: NullableDictionary) {
    this.forward( childEvent);
  }

  defaultState() : ArrayTreeChildrenState{
    return {instances: instances(this.props) as NullableDictionary[]}
  }

  state: ArrayTreeChildrenState = this.defaultState()

  forward(changeEvent: NullableDictionary) {
    // xxxx here this.props.onChange([changeEvent])
    this.props.onChange(changeEvent)
  }

  delete(i: number) {
    const change = {} as InstanceChange;
    const o2go = instances(this.props)[i]
    change.__id = _getId(o2go) as string
    change._a = ChangeAction.Delete;
    // xxxx here this.props.onChange([ change as unknown as NullableDictionary ])
    this.props.onChange( change as unknown as NullableDictionary)
    this.setState({instances: (this.props.instance as NullableDictionary[]).filter( 
       (o) => {
         return o!=o2go
       }

       )});
  } 

  render() {

    var idCount = 0
    const id = this.props.id

    const nextId = function () {
      return id + "" + idCount++
    }

    const idOf = function(instance: NullableDictionary) {
      return id + "." + -_getId(instance);
    }

    const component = this;

    var onChildEvent = function (i: number, childEvent: NullableDictionary) {
      component.childEvent(i, childEvent);
    }

    const onDelete = function(i: number) {
          component.delete(i);
    }

    var i =0;
 
    return (
      <React.Fragment>
        {
        this.state.instances.map(element => {
          const index = i;
          const localChildEvent = function (changeEvent: NullableDictionary) {
            onChildEvent(index, changeEvent);
          }
          const localDelete = function() {
              onDelete(index);  
          }

          const c = component.props.connection!!
          const strPath = component.props.path.map((p) => `${p.connection?.name}/`)
          const entity2Id = component.props.connection?.entity2.id
          if ("Role" == entity2Id){
            const onClickPropEdit = function(_event: any) {
               /* var value = getPendingEditValue(id as string, c.name as string)
                if (value && value!=childInstanceF) {
                     localApplyValue(value)
                }*/
              }
            
             const getEditValueOrDefault = function() {
                /*var value = getPendingEditValue(id as string, c.name as string)
                if (!value) {
                     value = childInstanceF
                     setPendingEditValue(id as string, c.name as string, value)
                } 
                return value*/
                return 'xxx'
             }

             const localStoreValue = function (_val: any) {
              //setPendingEditValue(id as string, c.name as string, val)
              //forceUpdate()
            }


            return <StyledTreeItem nodeId={nextId()} onIconClick={onClickPropEdit} label={`${c.name} : "${element}"`}>
              <PropertyEdit path={this.props.path.concat(this.props)} value={getEditValueOrDefault()} connection={c} onChange={localStoreValue} ></PropertyEdit>
            </StyledTreeItem>
          }

          else return <StyledTreeItem nodeId={getInstanceId(element)} label={`${"" + i++} : ${strPath} ${entity2Id}`}>
            <Button onClick={localDelete}> - delete</Button>
            <InstanceTreeChildren parentSegment={this.props.parentSegment}  pathSegments={this.props.pathSegments} 
                       path={component.props.path.concat(component.props)} isArray={false} id={nextId()} instance={element} metaData={component.props.metaData}
                                     onChange={localChildEvent} connection={null} >
                </InstanceTreeChildren>
          </StyledTreeItem>
        }
        )
        }
      </React.Fragment>)
  }
}



const pendingEditValues = {} as NullableDictionary

function getPendingEditValue(__id: string, name: string) : any {
   var changeParent = pendingEditValues[__id] as NullableDictionary
   var result 
   if (changeParent==null) {
       result = null
   } else {
       result =  changeParent[name]
   }
   //if (name=='sharedRelational') console.log(`getPendingEditValue ${__id} ${name} ${result}`)
   return result
}

function setPendingEditValue(__id: string, name: string, value: any) {
  var changeParent = pendingEditValues[__id] as NullableDictionary
  if (changeParent==null) {
       changeParent = {} as NullableDictionary
       pendingEditValues[__id]=changeParent        
  } 
  changeParent[name] = value
  //if (name=='sharedRelational') console.log(`setPendingEditValue ${__id} ${name} ${value}`)

}

function getDefaultReferenceId( _path: InstanceProps[], _connection: Connection): String {
     // find the schema 
     // find the parent in the schema
     // go up in the instance tree and find an instance of the parent schema
     return 'xyzxyz'
}

class InstanceTreeChildren extends React.Component<InstanceProps, NullableDictionary> {

  constructor(props: InstanceProps) {
    super(props);
    this.state = this.shallowClone(this.props.instance);
  }

  shallowClone(instance: object): NullableDictionary {
    const target = Object() as NullableDictionary;
    Object.keys(instance).forEach((key) => target[key] = (instance as NullableDictionary)[key as string]);
    return target;
  }

  forward(changeEvent: NullableDictionary) {
    this.props.onChange(changeEvent)
  }

  apply(connection: Connection, value: any) {
    const changeEvent = createUpdateEvent(this.state, connection, value);
    this.forward(changeEvent);
    var newState = this.shallowClone(this.props.instance as NullableDictionary);
   // (newState as NullableDictionary)[connection.name as string] = 
   //               (this.props.instance as NullableDictionary)[connection.name as string];
    this.setState(newState);
  }

  arrApply(_connection: Connection) {
    var newState = this.shallowClone(this.props.instance as NullableDictionary);
    this.setState(newState);
  }

  forceUpdate() {
    var newState = this.shallowClone(this.props.instance as NullableDictionary);
    this.setState(newState);
  }

  //TODO this doesnt work with references because user needs pick !
  addDefaultOne2Many(connection: Connection) {
    if (connection.connectionType==ConnectionType.Reference) {
       //getDefaultReferenceId(this.props.path, connection)
      const change = {} as NullableDictionary
      //TODO see above
      _setId(change, 'xxxTODOxxxy')
      _setAction(change, ChangeAction.Insert)   
      this.apply(connection, [change])
    } else { 
       const change = defaultNewInstance(this.props.metaData.schemat, connection.entity2, {} as Dictionary)
       this.apply(connection, [change])
    }
  }

  childEvent(connection: Connection, childEvent: NullableDictionary) {
    const updateEvent = createUpdateEvent(this.state, connection, childEvent)
    this.forward(updateEvent)
  }

  render() {
    const metaData = this.props.metaData;
    const id = this.props.id
    //const connections =  this.props.pathSegments.map  //permittedConnectionsFrom(metaData.schemat, metaData.entity)
    var idCount = 0
    const nextId = function () {
      return id + "" + idCount++
    }

    console.log(`rendering ${this.props.parentSegment?.connection?.name}  ${this.props.pathSegments.map((ps)=>{return ps.connection?.name})} `)

    const component = this;
    var onApply = function (connection: Connection, value: any) {
      component.apply(connection, value)
    }

    var onArrApply = function (connection: Connection) {
      component.arrApply(connection)
    }

    const forceUpdate=function() {
     component.forceUpdate()
    }

    var onChildEvent = function (connection: Connection, childEvent: NullableDictionary) {
      component.childEvent(connection, childEvent)
    }

    var onClickAddOne2Many = function (connection: Connection) {
      // if its a reference need to find the available refs 
      // requires current instance and schemat  
      component.addDefaultOne2Many(connection);
    }
    //              <InputGroup>
//<FormControl value={childInstanceF as string} onChange={localApply} />
    //</InputGroup>
    return (
      <React.Fragment>
        {this.props.pathSegments.map((segment) => {
          // TODO deal with null connection !
          const c = segment.connection!!

          var isOne2Many = c.cardinality == Cardinality.OneToMany;
          var childInstance = (this.state as NullableDictionary)[c.name as string]

          var childInstanceF = childInstance == null ? "" : childInstance
          var currentValue = null//childInstanceF
          const localApplyValue = function (val: any) {
                onApply(c, val);
              }
 
          //const localApply = function (event: any) {
          //  localApplyValue(event.target.value);
          //}
          const arrApply = function (event: any) {
             const changeEvent = component.childEvent(c, [event] as any)
             onArrApply(c)
          }
          // TODO complex value types re editable via externally supplied schema
          if (isOne2Many) {
               const childInstances = childInstance == null ? [] : (childInstance as []); 
               const childMetaData: MetaData = {schemat: this.props.metaData.schemat, entity : c.entity2 }
               const clickAdd = function() {
                // if its a reference need to find the available refs 
                // requires current instance and schemat
                onClickAddOne2Many(c);
               }
          return  <StyledTreeItem nodeId={nextId()} label={`${c.name}`}><Button onClick={clickAdd}>+ Add {c.name} </Button>
               <ArrayTreeChildren parentSegment={segment}  pathSegments={segment.segments}  path={component.props.path.concat(component.props)} isArray={true} connection={c} metaData={childMetaData} id={nextId()} instance={childInstances} onChange={arrApply} ></ArrayTreeChildren>
               </StyledTreeItem>
              }

          else if (c.entity2.isValueType || c.entity2.id == "Schemat" || c.entity2.id == "Role") {
            const onClickPropEdit = function(_event: any) {
                var value = getPendingEditValue(id as string, c.name as string)
                if (value && value!=childInstanceF) {
                     localApplyValue(value)
                }
              }
            
             const getEditValueOrDefault = function() {
                var value = getPendingEditValue(id as string, c.name as string)
                if (value==null) {
                     value = childInstanceF
                     setPendingEditValue(id as string, c.name as string, value)
                } 
                return value
             }

             const localStoreValue = function (val: any) {
              setPendingEditValue(id as string, c.name as string, val)
              //console.log(`localStoreValue '${c.name}' '${val}' `)
              forceUpdate()
            }


            return <StyledTreeItem nodeId={nextId()} onIconClick={onClickPropEdit} label={`${c.name} : "${childInstanceF}"`}>
              <PropertyEdit path={this.props.path.concat(this.props)} value={getEditValueOrDefault()} connection={c} onChange={localStoreValue} ></PropertyEdit>
            </StyledTreeItem>
          } else {
            if (childInstance == null) return <StyledTreeItem nodeId={nextId()} label={c.name}>null</StyledTreeItem>
            else {
              const childMetadata = _getMetaData(childInstance)
              const id = _getId(childInstance)
              //read the valuenu
              const localChildEvent = function (changeEvent: NullableDictionary) {
                onChildEvent(c, changeEvent);
              }
              return <StyledTreeItem nodeId={nextId()} label={c.name}>
                <InstanceTreeChildren parentSegment={segment} pathSegments={segment.segments}  path={component.props.path.concat(component.props)} isArray={false} id={id} instance={childInstance} metaData={childMetadata as MetaData}
                  onChange={localChildEvent} connection={c} >
                </InstanceTreeChildren></StyledTreeItem>
            }
          }
        }
        )}
      </React.Fragment>)
  }
}

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
  }),
);

export default function InstanceTreeView2(props: { path: Path, instance: NullableDictionary,
   changeSubject: Subject<{instanceIn: NullableDictionary, changeEventIn: NullableDictionary}> }) {
  const classes = useStyles();

  const instance = props.instance
  const metaData = _getMetaData(instance) as MetaData
  const id = _getId(instance)

  var onChildEvent = function (changeEvent: NullableDictionary) {
    props.changeSubject.next({instanceIn: instance, changeEventIn: changeEvent})
    //applyChanges(instance, [changeEvent], true);

  }



  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
    >
      {<InstanceTreeChildren parentSegment={null} pathSegments={props.path.segments}
           path={[]} id={id} isArray={false} onChange={onChildEvent}
               instance={instance} metaData={metaData} connection={null}/>}
    </TreeView>
  );
}
