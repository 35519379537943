import { Entity, Connection, Schemat, ConnectionType, Cardinality } from "./schemat"
import { connectionsFrom } from "./schemat-read-util"
import { uuid } from "uuidv4"

  
var defaultCount=0
  
//TODO modularize this
function defaultExternal(entity: Entity, id2Object: Object) : Object {
     if (entity.name === "String") {
       return "astring" + defaultCount++;
     } else if (entity.name === "Boolean") {
       return true;
     } 
     throw Error("unknown external " + entity.name )
}

function defaultValueType(entity: Entity) : Object{
  throw Error("unknown valueType " + entity.name )
}

//TODO put these somwhere generic
export type Dictionary = { [index: string]: object } 
export type NullableDictionary = { [index: string]: object | null }

const hiddenMetaDataField = "__schemat"
const hiddenIdField = "__id"
const hiddenActionField = "_a"
export const refField = "__ref"


export enum ChangeAction {
  Insert = "i",
  Update ="u" ,
  Delete ="d" ,
  None = "n" 
}

export interface InstanceChange {
  __schemat?: Schemat;
  __id?: string;
  //TODO remove this
  _del?: boolean;
  _a: ChangeAction 
}

export function createUpdateEvent(target: object, connection: Connection, newValues: object | null) : NullableDictionary  {
  //const metaData = _getMetaData(target);
  const id = _getId(target);
  //const connections = connectionsFrom(metaData?.schemat as Schemat, metaData?.entity as Entity);
  const result = Object() as NullableDictionary;
  result[hiddenIdField as string] = id;
  result[connection.name as string] = newValues;
  result[hiddenActionField] = ChangeAction.Update as String
  return result;
}

export type MetaData = {
  schemat: Schemat,
  entity: Entity
}

export function _setMetaData(instance: object, metaData: MetaData) {
  (instance as Dictionary)[hiddenMetaDataField]=metaData
}

export function _getMetaData(instance: object) : MetaData | null {
  const result = (instance as Dictionary)[hiddenMetaDataField]
  return result ==null ? null : result as MetaData
}

export function _setId(instance: object, id: String) {
  (instance as Dictionary)[hiddenIdField]=id as object
}

export function _getId(instance: object) : String {
  return (instance as Dictionary)[hiddenIdField] as String
}

export function _setAction(instance: object, action: ChangeAction) {
  (instance as Dictionary)[hiddenActionField]=action as unknown as object
}

export function _getAction(instance: object) : String {
  return (instance as Dictionary)[hiddenActionField] as unknown as ChangeAction
}


export function defaultNewInstance(schemat: Schemat, entity: Entity, id2Object: Dictionary) : Object  {
      if (entity.isExternal) {
           return  defaultExternal(entity, id2Object); 
      } 
      if (entity.isValueType) {
         return defaultValueType(entity)
      }
    
      const newvalue:  Dictionary = Object() as Dictionary
      (newvalue as unknown as InstanceChange)._a = ChangeAction.Insert

      const connections = connectionsFrom(schemat, entity)

      connections.forEach(
          ( c ) => {
              if (c.connectionType===ConnectionType.Contains) {
                   var child = defaultNewInstance(schemat, c.entity2, id2Object)
                   if (c.cardinality === Cardinality.OneToMany || (c.minTo && c.minTo >1)) {
                         child = [child]
                   }
                   newvalue[c.name as string] = child         
              }
          }
      )
      const newid = uuid()
      _setMetaData(newvalue, {schemat: schemat, entity:entity})
      _setId(newvalue, newid) 
      id2Object[newid] = newvalue
      return newvalue;  
  }

//TODO deal with non contained references
// TODO remove schemat from request body
export function instanceAsChange(instance: NullableDictionary) : NullableDictionary {
   const change = {} as NullableDictionary
   _setId(change, _getId(instance))
   _setAction(change, _getAction(instance) as ChangeAction)
   const metaData = _getMetaData(instance);
   //if there are no metadata assumeits not necessary
   if (!metaData) {
        for (var prop in instance) {
            const subValue = instance[prop]     
            if (Array.isArray(subValue)) {
              change[prop] = (subValue as []).map((item)=>  instanceAsChange(item as NullableDictionary))
            }
            else if (subValue && typeof(subValue) == 'object') {
                change[prop] = instanceAsChange(subValue as NullableDictionary)
            } else {
                change[prop] = subValue
            }
        }
        return change
   }

   const connections = connectionsFrom(metaData?.schemat!!, metaData?.entity!!);
   connections.forEach(
     (c) => {
          const pValue = instance[c.name as string];
          if (pValue == null) {
               //DO nothing
          }  
          //TODO deal with cardinality
          else if (c.cardinality === Cardinality.OneToMany || (c.minTo && c.minTo>1)) {
                 if (c.connectionType!=ConnectionType.Contains)     
                    throw Error(`instanceAsChange cant deal with 1 to many non contained ${c.entity1.name}.${c.name}`)
                if (pValue!=null) {
                    const arrayChange = []
                    for (var i = 0; i< (pValue as []).length; i++) {
                       arrayChange.push(instanceAsChange((pValue as [])[i] as NullableDictionary))
                    }
                    change[c.name as string] = arrayChange;
                }
            } else if (c.connectionType === ConnectionType.Reference) {
            throw Error(`instanceAsChange cant deal with reference ${c.entity1.name}.${c.name}`)
          }
          else if (c.entity2.isValueType || c.entity2.isExternal) {
            change[c.name as string] = pValue 
          } else {
            change[c.name as string] = instanceAsChange(pValue as NullableDictionary);
          }
     }
   );
   
   return change
}  
