import { API, Auth } from 'aws-amplify';
import { SchematSummary, getEntityById, findEntityImportById, getConnectionById, getEntityViewById } from './schemat-read-util'
import { Schemat, ConnectionChange, Connection, Change, Entity, EntityChange, EntityView, EntityViewChange, Cardinality, ConnectionType, SchematChange } from './schemat';
import { NullableDictionary, _getMetaData, _getId, Dictionary } from './instance-util';
import { isArray } from 'util';
import { RectangleBounds, Point } from './schemat-externals';
import { rebuildSchemat, importedEntityPlaceholderName, resolveIds } from './schemat-changeprocessor'

export async function retrieveByLoggedInUser(): Promise<SchematSummary[]> {
  const apiName = "schemactCrudfunction";
  const path = "/retrieveByLoggedInUser";
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  };

  return await API.get(apiName, path, myInit);
}

export async function retrieveByIdResolveImports(id: String): Promise<Schemat> {
  //TODO avoid trying to resolve external imports
  // or resolve import changes first !

  var schemat = await retrieveById(id);
  const preresolved = true

  if (!preresolved && schemat.imports != null) {
    console.log("imports: ", schemat.imports);
    for (var index = 0; schemat.imports && index < schemat.imports.length; index++) {
      var localIndex = index;
      const importId = schemat.imports[localIndex].id;
      console.log("loading import:" + importId);
      const dependency = await retrieveByIdResolveImports(importId as unknown as string)
      console.log("loaded import:" + importId + " at index " + localIndex);
      schemat.imports[localIndex] = dependency;
      console.log("setting import:" + importId + ":" + dependency.name + " at index " + localIndex + " in " + schemat.id + ":" + schemat.name);
    }
    // now resolve imports !
    schemat.entityViews.forEach((ev) => {
      const importedEntity = findEntityImportById(schemat, ev.entity.id);
      if (importedEntity != null) {
        ev.entity = importedEntity;
      } else if (importedEntityPlaceholderName === ev.entity.name) {
        console.log("can find import ", schemat, ev.entity.id);
      }
    });
    schemat.connections.forEach((con) => {
      var importedEntity;
      importedEntity = findEntityImportById(schemat, con.entity1.id);
      if (importedEntity != null) {
        con.entity1 = importedEntity;
      } else if (importedEntityPlaceholderName === con.entity1.name) {
        console.log("can find import connection entity1 ", schemat, con.entity1.id);
      }
      importedEntity = findEntityImportById(schemat, con.entity2.id);
      if (importedEntity != null) {
        con.entity2 = importedEntity;
      } else if (importedEntityPlaceholderName === con.entity2.name) {
        console.log("can find import connection entity2 ", schemat, con.entity2.id);
      }
    });



  }
  return schemat
}


export async function retrieveById(id: String): Promise<Schemat> {
  const apiName = "schemactCrudfunction";
  //    const path =  `/retrieveById/${id}`;  
  const path = `/currentSnapshotById/${id}`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }/*,
    queryStringParameters: {  // OPTIONAL
      id: id,
  },*/
  };

  var resultPromise = await API.get(apiName, path, myInit).then(
    (result) => {
      resolveIds(result)

      //if (!isArray(result)) {
      //  result = [result]
      //}
      const schemat = result//  rebuildSchemat(result, id)

      // TODO load externals here 
      console.log("retrieveById loaded ", schemat);
      //TODO this is for somewhere else *****
      //schemats.push(schemat);
      return schemat
    }
  )

  return resultPromise;
}



