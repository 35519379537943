import { Connection, Role, PathSegment } from "./schemat";
import { SchematSummary } from "./schemat-read-util";
import React, { Fragment } from 'react';
import { retrieveByLoggedInUser, retrieveByIdResolveImports } from "./schemat-service";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { MetaData, NullableDictionary } from "./instance-util";

export type InstanceProps = {
    instance: object;
    isArray: boolean;
    metaData: MetaData;
    id: String;
    parentSegment: PathSegment | null;
    pathSegments: PathSegment[];
    connection: Connection | null;
    path: InstanceProps[]
    onChange: (change: NullableDictionary) => void;
  }

export type PropertyProps = {
    value: any
    connection: Connection;
    //onChange: (connection: Connection, change: any) => void;
    path: InstanceProps[]
    onChange: (event: any) => void;
  }
  
  export type PropertyState = {
    summaries: SchematSummary[];
    roles: Role[];
  }
  
  export class PropertyEdit extends React.Component<PropertyProps, PropertyState> {
  
    entityId : String
  
  
    constructor(props: PropertyProps) {
      super(props);
      this.entityId = this.props.connection.entity2.id 
      this.state = { summaries: [], roles: []}
      if (this.entityId == "Schemat") {
      retrieveByLoggedInUser().then(summaries=> {
          const fSummaries = summaries
          //.filter((s)=>s.childId!=null);
          this.setState({summaries: fSummaries }); 
      })
    } 

    if (this.entityId == "Role") {

        const schematid = (this.props.path[this.props.path.length-1].instance as NullableDictionary)['schemat']  as unknown as string


        //TODO - find peer schemat
        //this.setState ({ loading: true})
        if (schematid) {
        retrieveByIdResolveImports(schematid).then(
           (schemat) =>  {
             console.log("********* found ", schemat)  
             this.setState({roles: schemat.roles })
           }
        )
        }
        

       }
    }
  
    render() {
     const component = this;

    var strPath: String = ''
    this.props.path.forEach(element => {
        strPath = `${strPath}/${element.connection?.name}`
    });
    //console.log(`strPath=${strPath}`, this.props)
  
    var strTrueChecked =  this.props.value?"checked" : ""
    var strFalseChecked =  this.props.value?"" : "checked"
  var control;
  //onChange={this.handleChange}
  const clickBoolean= function(event: any) {
    //console.log("clickBoolean" + event.target.id + " " + event.target.id == "true")
    const newval = (event.target.id == "true")
    //event.target.checked=true
    //console.log("event, target, event.target.checked:", event, event.target, event.target.checked)
  component.props.onChange(newval)
  }
  const clickSelect= function(event: any) {
  component.props.onChange(event.currentTarget.selectedOptions[0].value)
  }
  
  
  if (this.entityId === "Boolean") {
    control = <Fragment>
      <Form.Check
    type="radio"
    label="true"
    name="boolean"
    checked = {this.props.value as boolean}
    onChange={clickBoolean}
    id="true"
  />
  <Form.Check
    type="radio"
    label="false"
    name="boolean"
    checked = {!(this.props.value as boolean)}
    onChange={clickBoolean}
    id="false"
  /></Fragment>
  } else if (this.entityId === "String") {
    const clickText = function(event: any) {
      component.props.onChange(event.target.value)
      return true
    }
    control = <FormControl value={`${this.props.value}`} onChange={clickText} />
  } else if (this.entityId == "Schemat") {
  control = <Form.Control
      as="select"
      className="my-1 mr-sm-2"
      id="schemat"
      custom
      onChange={clickSelect}
    >
  
      {
      this.state.summaries.map((sum)=> {
      const selectedv = sum.id == component.props.value    
      return <option selected = {selectedv} value={sum.id as string}>{sum.name}:{sum.version}</option>
      }) 
      }
    </Form.Control>
  } else if (this.entityId == "Role") {
    control = <Form.Control
        as="select"
        className="my-1 mr-sm-2"
        id="role"
        custom
        onChange={clickSelect}
      >
        <option value=""></option>
        {
          
        this.state.roles.map((role)=> {
        const selectedv = role.id == component.props.value    
        return <option selected = {selectedv} value={role.id as string}>{role.name}</option>
        }) 
        }
      </Form.Control>
    }
  else  {
  control = <Form.Label>{"unprocessed entity type " + this.props.connection.entity2.id}</Form.Label>
  }
  
  return  <InputGroup> {control} </InputGroup>
  
  }
  }
  