import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import {SchemasList} from './SchemasList';
import {SchematSummary} from './schemat-read-util';
import {retrieveByLoggedInUser, retrieveById, retrieveByIdResolveImports } from './schemat-service';
import {_getId, NullableDictionary, _getMetaData} from './instance-util';
import { Schemat, Entity, Path } from './schemat';
import { EntityList } from './EntityList';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import InstanceTreeView2 from './InstanceTreeView2';
import { EntityViewComponent } from './EntityViewComponent';
import { createInstanceSnapshot } from './instance-service';

import { Subject, Subscription } from 'rxjs'
import { applyChanges } from './instance-state';

const changeSubject = new Subject<{instanceIn: NullableDictionary, changeEventIn: NullableDictionary}>()
var subscriptionCount = 0

var unsub: Subscription | null = changeSubject
.subscribe(data => {
  console.log(`${subscriptionCount} received change `, data)
  applyChanges(data.instanceIn, [data.changeEventIn], true);
  console.log(`${subscriptionCount} processed change `, data)
})


type Crumble = {
  name:  String,
  modifyState: (appState: AppState) => void
}

type AppState = {
  summaries: SchematSummary[],
  loading: Boolean,
  selectedSchemat: Schemat | null,
  selectedPath: Path | null,
  selectedInstance: Object | null,
  crumbles: Crumble[]
};


class App extends Component<{}, AppState> {


  constructor(props: {}) {
    super(props)

    //const currentSubscriptionCount = subscriptionCount++
  }

  componentWillUnmount() {
   // (this.unsub as Subscription).unsubscribe()
  }

  rootCrumble() : Crumble {
     return {name: "home", modifyState: (appState)=>{}}  
  }

  schemaCrumble(schemat: Schemat) : Crumble {
    return {name: schemat.name,  modifyState: (appState)=>{appState.selectedSchemat=schemat}}  
 }

 pathCrumble(path: Path) : Crumble {
  return {name: path.name,   modifyState: (appState)=>{appState.selectedPath=path}}  
}

schematsCrumble(summaries: SchematSummary[]) : Crumble {
  return {name: "Schemats",  modifyState: (appState)=>{appState.summaries=summaries}}  
}

snapshotInstance(instance: NullableDictionary) {
  const metaData = _getMetaData(instance)
  createInstanceSnapshot(metaData?.schemat.id!!, _getId(instance), metaData?.schemat.version!!)
}

instanceCrumble(instance: Object) : Crumble {
  return {name: "" + _getId(instance),  modifyState: (appState)=>{
    appState.selectedInstance=instance}
}}


  defaultState() : AppState {
      return {summaries: [], loading: false, selectedSchemat: null, 
        selectedPath: null, selectedInstance: null, crumbles: [this.rootCrumble()] }
  }

  state: AppState =  this.defaultState()

  addCrumble(crumble: Crumble) : Crumble[] {
    var cs = [...this.state.crumbles] as Crumble[]
    cs.push(crumble)
    return cs
  }

  popCrumble() : Crumble[] {
    var cs = this.state.crumbles
    return cs.slice(0, cs.length-1)
  }

   navCrumble(destinationCrumble: Crumble) {

      const newState = this.defaultState(); 
      const newCrumbles = []
      for (var done=0; done<this.state.crumbles.length; done++) {
          const crumble = this.state.crumbles[done];
          crumble.modifyState(newState) 
          newCrumbles.push(crumble);
          if (crumble===destinationCrumble) {
                break;
          }
      }
      newState.crumbles = newCrumbles
      this.setState(newState)
   }


  onSelectSummary(summary: SchematSummary) {
     this.setState ({ loading: true})
     retrieveByIdResolveImports(summary.id).then(
        (schemat) =>  {
          this.setState({ loading: false, selectedSchemat: schemat, crumbles: this.addCrumble(this.schemaCrumble(schemat))})
        }
     )
  }

  onClickRetrieveSchemas() {
      this.setState ({ loading: true})
      retrieveByLoggedInUser().then(summaries=> {
          const fSummaries = summaries
          //.filter((s)=>s.childId!=null);
          this.setState({summaries: fSummaries, loading: false
            , crumbles: this.addCrumble(this.schematsCrumble(fSummaries))
          }); 
      })
  }

  cancel() {
    this.navCrumble(this.state.crumbles[this.state.crumbles.length-2])
  }

  render() {
    var logoClass = `App-logo${this.state.loading?" App-logo-start":""}`

    const current: App = this

    var ocr= function() {
      current.onClickRetrieveSchemas()
    } 

    var schematSelected = function( summary: SchematSummary) {
       current.onSelectSummary(summary);
    }
    
    var pathSelected =  function(path: Path) {
         current.setState({ selectedPath: path, crumbles: current.addCrumble(current.pathCrumble(path)) }) 
    }

    var instanceSelected = function( instance: Object) {
      current.setState({ selectedInstance: instance, crumbles: current.addCrumble(current.instanceCrumble(instance)) }) 
   }

    var onClickCrumble = function(crumble: Crumble) {
      current.navCrumble(crumble)
    }

    var onClickSnapshotInstance = function() {
      current.snapshotInstance(current.state.selectedInstance!! as NullableDictionary)
    }

 var body;
 //var cancel =  <Button onClick={onCancel} disabled={this.state.crumbles.length>0} > &lt;-- Back </Button> 
    
    if (this.state.selectedInstance!=null) {
      body=<Fragment> 
        <Button onClick={onClickSnapshotInstance}>Snapshot</Button>
        <InstanceTreeView2 path={this.state.selectedPath!!} instance={this.state.selectedInstance as NullableDictionary} changeSubject={changeSubject}  />
      </Fragment>
    }
    else if (this.state.selectedPath) {
      body = <div> Path: {this.state.selectedPath.name}
       <EntityViewComponent schemat={this.state.selectedSchemat as Schemat} 
              onSelect = {instanceSelected}
              path={this.state.selectedPath} instances={[]} />
              </div>
    }
    else if (this.state.selectedSchemat) {
      body = <div> paths in {this.state.selectedSchemat.name}  <br/>
        <EntityList schemat={this.state.selectedSchemat} onSelect={pathSelected}/>
         </div>
    } else if (this.state.summaries !=null && this.state.summaries.length>0) {
      body = <div> Schemas  <br/> 
       <SchemasList summaries={this.state.summaries} onSelect={schematSelected} />
       </div>
    } else {
      body = 
      <Button title="Send Request" onClick={ocr} >Load Schemas</Button>
    }

    const crumbles = this.state.crumbles

    return <Jumbotron className="App">
      <Navbar bg="light" expand="lg"> 
      <Nav className="mr-auto">
      <Breadcrumb>
      {crumbles.map(
        (cr) => {
        var clickCrumble = function() {onClickCrumble(cr);}  
        return <Breadcrumb.Item onClick={clickCrumble}>{cr.name}</Breadcrumb.Item>
        }
      )}     
      </Breadcrumb>
      </Nav>
      <Nav></Nav>
      <Nav className="mr-sm-2">
      <AmplifySignOut />
      </Nav>
      </Navbar>
      <img src={logo} className={logoClass} alt="logo" />
        {body}
    </Jumbotron>
  }
}

export default withAuthenticator(App);
