import {Component} from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import React from 'react';
import { Schemat, Entity, Path } from './schemat';
import {isRoot} from './schemat-read-util'

//TODO change this to PathList
class EntityList extends Component<
         {schemat: Schemat,
          onSelect: (path: Path) => void
        }> {

render() {
const onSelect = this.props.onSelect

return (
  <ListGroup defaultActiveKey="#link1">
    {this.props.schemat.paths
           //.filter((e)=>isRoot(this.props.schemat, e))
           .map(function (path, index) {
      const alertClicked =  function () {
        onSelect(path)
      }
      return (
        <React.Fragment key={index.toString()}>
    <ListGroup.Item action onClick={alertClicked}>
    {path.name}
    </ListGroup.Item>

        </React.Fragment>
    )})
      }    
     </ListGroup>
  );
}
}

export {EntityList}