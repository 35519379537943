import {Component} from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import React from 'react';
//import bootstrapUtils from 'react-bootstrap/lib/utils/bootstrapUtils';
import { SchematSummary } from './schemat-read-util';

class SchemasList extends Component<
         {summaries: SchematSummary[],
          onSelect: (summary: SchematSummary) => void
        }> {
 
render() {

const onSelect = this.props.onSelect

return (

  <ListGroup defaultActiveKey="#link1">
    {
    this.props.summaries.map(function (summary, index) {
      const alertClicked =  function () {
        onSelect(summary)
      }
      const isReleased = summary.childId?true:false
      const variant = isReleased?"success":"danger" 
      return (
        <React.Fragment key={index.toString()}>
    <ListGroup.Item variant={variant}  action onClick={alertClicked}>
    {summary.name} : {summary.version}
    </ListGroup.Item>
      </React.Fragment>
    )}
    )}    
     </ListGroup>
  );
}
}


export {SchemasList}