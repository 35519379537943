import { NullableDictionary, _getMetaData, _getId, MetaData } from "./instance-util";
import { Auth, API } from "aws-amplify";
import { newid } from "./schemat-changeprocessor";

export async function saveInstanceChange(events: NullableDictionary[], metaData: MetaData, id: String) {
    const apiName = "instanceCrudfunction";
    const path = `/save/${metaData?.schemat.id}/${metaData?.entity.id}/${id}`;

    //remove meta data ?

    const myInit = { 
      headers: { 
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: events
    };

    return await API.post(apiName, path, myInit);
   }

   export async function retrieveBySchematIdEntityId(schematId: String, entityId: String) : Promise<NullableDictionary[][]>{
    const apiName = "instanceCrudfunction";
//    const path =  `/retrieveById/${id}`;  
    const path =  `/retrieveBySchematIdEntityId/${schematId}/${entityId}`;
    const myInit = { 
      headers: { 
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }/*,
      queryStringParameters: {  // OPTIONAL
        id: id,
    },*/
    };

    return await API.get(apiName, path, myInit).then(
         (result) => {   
             const typedResult = (result as NullableDictionary[][]);
             return typedResult
         }
    )
  }

  
export async function createInstanceSnapshot(schematId: String, id: String, version: String) {
    const snapshotId = newid()
    const apiName = "instanceCrudfunction";
    const path = `/createSnapshot/${schematId}/${id}/${snapshotId}/${version} `;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    };

    return await API.post(apiName, path, myInit).then(results => {
      return snapshotId;
    })
  }