import {Shape, ShapeChange, RectangleBounds, Point} from './schemat-externals'


export enum ChangeAction {
    Insert = "i",
    Update ="u" ,
    Delete ="d" ,
    None = "n" 
  }
              
  export interface Change {
      __id: String
      // not need if all changes are by context
      _a: String
      type?: String
  }    
  
      
  
  export class Schemat {
      constructor ( public id: String, public name: String, public viewPort: RectangleBounds, public diagramWidth: number, public diagramHeight: number, public toolbarTopLeft: Point, public entities: Entity[]  = [], public entityViews: EntityView[]  = [], public connections: Connection[]  = [], public roles: Role[]  = [], public roleViews: RoleView[]  = [], public paths: Path[]  = [], public pathViews: PathView[]  = [], public packageName?: String, public idEntity?: Entity, public imports: Schemat[]  = [], public version?: String ) {
      }
  }    
  
  export interface SchematChange extends Change {
  id: String    
  name?: String    
  entities?: EntityChange[]     
  entityViews?: EntityViewChange[]     
  connections?: ConnectionChange[]     
  roles?: RoleChange[]     
  roleViews?: RoleViewChange[]     
  paths?: PathChange[]     
  pathViews?: PathViewChange[]     
  viewPort?: RectangleBounds    
  diagramWidth?: number    
  diagramHeight?: number    
  toolbarTopLeft?: Point    
  packageName?: String    
  idEntity?: EntityChange    
  imports?: String[]     
  version?: String
  }
  
      
  
  export class Entity {
      constructor ( public id: String, public name: String, public isValueType: boolean, public kotlinName?: String, public typescriptName?: String, public sqlType?: String, public isExternal?: boolean ) {
      }
  }    
  
  export interface EntityChange extends Change {
  id: String    
  name?: String    
  kotlinName?: String    
  typescriptName?: String    
  sqlType?: String    
  isExternal?: boolean    
  isValueType?: boolean
  }
  
  export enum Cardinality { OneToOne = 'OneToOne', OneToMany = 'OneToMany' }
  export enum ConnectionType { Contains = 'Contains', Reference = 'Reference' }    
  
  export class Connection {
      constructor ( public id: String, public name: String, public entity1: Entity, public entity2: Entity, public cardinality: Cardinality, public connectionType: ConnectionType, public isId?: boolean, public minTo?: number, public isEnumeration?: boolean, public permissions?: Permissions, public enumeratedValues: String[]  = [] ) {
      }
  }    
  
  export interface ConnectionChange extends Change {
  id: String    
  name?: String    
  entity1?: String    
  entity2?: String    
  isId?: boolean    
  minTo?: number    
  isEnumeration?: boolean    
  permissions?: PermissionsChange    
  enumeratedValues?: String[]     
  cardinality?: Cardinality    
  connectionType?: ConnectionType
  }
  
      
  
  export class EntityView {
      constructor ( public id: String, public entity: Entity, public x: number, public y: number, public width: number, public height: number ) {
      }
  }    
  
  export interface EntityViewChange extends Change {
  id: String    
  entity?: String    
  x?: number    
  y?: number    
  width?: number    
  height?: number
  }
  
      
  
  export class ViewPosition {
      constructor ( public x: number, public y: number, public width: number, public height: number ) {
      }
  }    
  
  export interface ViewPositionChange extends Change {
  x?: number    
  y?: number    
  width?: number    
  height?: number
  }
  
      
  
  export class Role {
      constructor ( public id: String, public name: String, public paths: Path[]  = [] ) {
      }
  }    
  
  export interface RoleChange extends Change {
  id: String    
  name?: String    
  paths?: String[] 
  }
  
      
  
  export class RoleView {
      constructor ( public id: String, public role: Role, public position: ViewPosition ) {
      }
  }    
  
  export interface RoleViewChange extends Change {
  id: String    
  role?: String    
  position?: ViewPositionChange
  }
  
      
  
  export class Path {
      constructor ( public id: String, public name: String, public root?: Entity, public entityDefault?: boolean, public permissions?: Permissions, public segments: PathSegment[]  = [] ) {
      }
  }    
  
  export interface PathChange extends Change {
  id: String    
  name?: String    
  root?: String    
  entityDefault?: boolean    
  permissions?: PermissionsChange    
  segments?: PathSegmentChange[] 
  }
  
      
  
  export class PathView {
      constructor ( public id: String, public path: Path, public position: ViewPosition ) {
      }
  }    
  
  export interface PathViewChange extends Change {
  id: String    
  path?: String    
  position?: ViewPositionChange
  }
  
      
  
  export class PathSegment {
      constructor ( public id: String, public connection?: Connection, public allProperties?: boolean, public permissions?: Permissions, public segments: PathSegment[]  = [] ) {
      }
  }    
  
  export interface PathSegmentChange extends Change {
  id: String    
  connection?: String    
  allProperties?: boolean    
  permissions?: PermissionsChange    
  segments?: PathSegmentChange[] 
  }
  
      
  
  export class Permissions {
      constructor ( public id: String, public read?: boolean, public write?: boolean, public deny?: boolean ) {
      }
  }    
  
  export interface PermissionsChange extends Change {
  id: String    
  read?: boolean    
  write?: boolean    
  deny?: boolean
  }
  