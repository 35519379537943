import { NullableDictionary, _getMetaData, _getId, MetaData, instanceAsChange, _setId, _setMetaData, InstanceChange, ChangeAction } from "./instance-util";
import { connectionsFrom } from './schemat-read-util'
import { Schemat, Entity, Connection, ConnectionType, Cardinality, Change } from "./schemat";
import { saveInstanceChange, retrieveBySchematIdEntityId } from "./instance-service";

const entityId2Instances: Map<string, NullableDictionary[]> = new Map()

export async function getInstancesByEntity(schemat: Schemat, entity: Entity): Promise<NullableDictionary[]> {
    var result = entityId2Instances.get(entity.id as string);
    if (result == null || result.length == 0) {
        return retrieveBySchematIdEntityId(schemat.id as string, entity.id as string).then(
            (allInstanceChanges) => {
                const instances: NullableDictionary[] = []
                for (var index = 0; index < allInstanceChanges.length; index++) {
                    const instanceChanges = allInstanceChanges[index];
                    const instance = {}
                    const instanceid = _getId(instanceChanges[0])
                    _setId(instance, instanceid)
                    _setMetaData(instance, { schemat: schemat, entity: entity })
                    applyChanges(instance, instanceChanges, false)
                    instances.push(instance)
                }
                entityId2Instances.set(entity.id as string, instances);
                return instances;
            }
        )
    } else return result as NullableDictionary[];
}

export function getInstancesByEntityIdLocal(entityid: string): NullableDictionary[] {
    return entityId2Instances.get(entityid) as NullableDictionary[];
}

export function getInstanceById(entity: Entity, id: string): NullableDictionary | null {
    var instances = entityId2Instances.get(entity.id as string);
    if (instances == null) {
        return null;
    } else {
        var result = null;
        for (var index = 0; result == null && index < instances.length; index++) {
            var instance = instances[index];
            const iid = _getId(instance);
            if (iid === id) {
                result = instance
            }
        }
        return result;
    }
}

export function addInstance(entity: Entity, value: NullableDictionary) {
    var instances = entityId2Instances.get(entity.id as string);//  getInstances(schematid, entity);
    if (instances == null) {
        instances = <NullableDictionary[]>[];
        entityId2Instances.set(entity.id as string, instances);
    }
    instances.push(value);
    // should convert to a chnage really fy reolcing references to ids
    const instanceChange = instanceAsChange(value);
    saveInstanceChange([instanceChange], _getMetaData(value) as MetaData, _getId(value))
    return instances;
}

function getById(items: NullableDictionary[], id: String) {
    if (items == null || id == null) {
        return null;
    }
    for (var i = 0; i < items.length; i++) {
        if (id === _getId(items[i])) {
            return items[i];
        }
    }
    return null;
}

export function applyChange(target: NullableDictionary, change: NullableDictionary, connections: Connection[], schemat: Schemat) {
    connections.forEach(c => {
        var subChange = change[c.name as string]
        if (subChange != null && subChange != undefined) {
            const isArray = c.cardinality === Cardinality.OneToMany
            if (c.entity2.isValueType || (c.connectionType == ConnectionType.Reference && !isArray)) {
                // TODO resolve references on view ?
                target[c.name as string] = subChange
            } else {
                var subtarget = target[c.name as string]

                if (subtarget == null || subtarget == undefined) {
                    if (isArray) {
                        subtarget = []
                    } else {
                        subtarget = {}
                        _setId(subtarget, _getId(subChange))
                        _setMetaData(subtarget, { schemat: schemat, entity: c.entity2 })
                    }
                    target[c.name as string] = subtarget
                }
                if (isArray) {
                    //TODO do array merge
                    for (var i = 0; i < (subChange as []).length; i++) {
                        var subChangei = (subChange as [])[i]

                        var targetI = getById(subtarget as [], _getId(subChangei));
                        if (ChangeAction.Delete !=  (subChangei as InstanceChange)._a) {
                            if (targetI == null) {
                                targetI = {}
                                _setId(targetI, _getId(subChangei));
                                _setMetaData(targetI, { schemat: schemat, entity: c.entity2 });
                                (subtarget as object[]).push(targetI as any);
                            }
                            applyChange(targetI, subChangei, connectionsFrom(schemat, c.entity2), schemat);
                        } else {
                            const newSubTarget = (subtarget as []).filter((s)=>{  return s!=targetI })
                            target[c.name as string] = newSubTarget 
                        }
                    }
                } else {
                    var subConnections = connectionsFrom(schemat, c.entity2 as Entity)
                    applyChange(subtarget as NullableDictionary, subChange as NullableDictionary, subConnections, schemat)
                }
            }
        }
    });
}

export function applyChanges(target: NullableDictionary, changes: NullableDictionary[], saveIt: boolean) {
    var schema = _getMetaData(target);
    var id = _getId(target);
    var connections = connectionsFrom(schema?.schemat as Schemat, schema?.entity as Entity)
    changes.filter(change => id == _getId(change))
        .forEach(
            change => {
                applyChange(target, change, connections, schema?.schemat!!);
            }
        )

    if (saveIt){ 
        saveInstanceChange(changes.map((change=>instanceAsChange(change))), schema as MetaData, id);
    }

}
